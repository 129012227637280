import { AccountStore, ConfigsStore } from '../Store';
import { API_KEY, BUNDLE_ID } from '../configs';
import axios from 'axios';
import { AxiosHeaders, IAccount } from '../types';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';

import {
  apiRequest,
  apiRequestPost,
} from '../providers/rest-data-provider/utils/APIs';
import { notificationProvider } from '../providers/notificationProvider';
import { AuthActionResponse } from '@refinedev/core/dist/index';
import showToastify from '../providers/notificationProviderCustom';

export const axiosInstance = axios.create() as AxiosHeaders;
axiosInstance.interceptors.response.use(
  async (response: { headers: { [x: string]: any } }) => {
    const extendedAccessToken = response.headers['x-extended-access-token'];

    if (extendedAccessToken) {
      const accountData = await AccountStore.get('account');

      if (accountData) {
        accountData.tokens.access_token = extendedAccessToken;
        await AccountStore.set('account', accountData);
        axiosInstance.defaults.headers.Authorization = `Bearer ${extendedAccessToken}`;
      }
    }

    return response;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

const setHeaders = async () => {
  try {
    const account = await AccountStore.get('account');

    if (account && account.tokens && account.tokens.access_token) {
      axiosInstance.defaults.headers = {
        api_key: API_KEY,
        Authorization: `Bearer ${account.tokens.access_token}`,
        device_id: await ConfigsStore.get('device_id'),
        // Configs_id: CONFIGS_ID,
        Bundle_id: BUNDLE_ID,
      };
    } else {
      axiosInstance.defaults.headers = {
        api_key: API_KEY,
        device_id: await ConfigsStore.get('device_id'),
      };
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error setting headers:', error);
  }
};

setHeaders();
export const LoginAction = async (loginInput: {
  email: string;
  password: string;
}): Promise<AuthActionResponse> => {
  try {
    const { data: accountData } = await apiRequest<IAccount>({
      url: '/calculation-module-admin/login',
      data: loginInput,
      method: 'POST',
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        'Content-Type': 'application/json',
        API_KEY,
        // Configs_id: CONFIGS_ID,
        Bundle_id: BUNDLE_ID,
      },
    });
    if (!accountData) throw new Error('Account Not Found!');
    await AccountStore.set('account', accountData);
    await ConfigsStore.set('logged_in', true);
    await ConfigsStore.set('resetPasswordEmail', '');
    if (await ConfigsStore.get('rememberMe')) {
      const cookies = {
        value: await ConfigsStore.get('logged_in'),
        expiry: dayjs(Date()).add(15, 'd').toDate(),
      };
      await ConfigsStore.set('cookieExpiration', cookies);
    }
    axiosInstance.defaults.headers = {
      // @ts-ignore
      Authorization: `Bearer ${accountData.tokens.access_token}`,
      api_key: API_KEY,
      device_id: await ConfigsStore.get('device_id'),
    };
    Sentry.setUser({
      id: accountData?.id.toString(),
      email: accountData?.email,
      name: accountData?.name,
      device: await ConfigsStore.get('device_id'),
    });
    return {
      success: true,
      message: 'Login Successful',
      redirectTo: '/',
    };
  } catch (error) {
    return {
      success: false,
      error: {
        name: 'Login Error',
        message: `${error}`,
      },
    };
  }
};
export const LogoutAction = async (
  withRefresh?: boolean
): Promise<AuthActionResponse> => {
  console.log('logout');
  await AccountStore.set('account', {});
  await ConfigsStore.set('logged_in', false);
  await ConfigsStore.set('rememberMe', false);
  await ConfigsStore.set('cookieExpiration', '');
  await ConfigsStore.set('adminRoles', '');
  await ConfigsStore.set('resetPasswordEmail', '');
  sessionStorage.clear();
  // set sentry user to null on logout
  Sentry.setUser(null);
  if (withRefresh) {
    await window.location.reload();
  }
  return {
    success: true,
    redirectTo: '/login',
  };
};

export const ForgotPasswordAction = async (
  inputs: { email: string },
  navigateToVerification: (email: string) => void,
  handleDisableButton: (value: boolean) => void
) => {
  handleDisableButton(true);
  try {
    await apiRequestPost<boolean>({
      url: '/calculation-module-admin/forget-password',
      data: inputs,
    });
    await ConfigsStore.set('resetPasswordEmail', inputs.email);
    handleDisableButton(false);
    navigateToVerification(inputs.email);
  } catch (error) {
    handleDisableButton(false);
    notificationProvider.open?.({
      type: 'error',
      message: 'Error',
      description: 'Incorrect email',
    });
  }
};

export const VerificationAction = async (
  inputs: { code: string },

  navigateToResetPassword: () => void,
  handleDisableButton: (value: boolean) => void
) => {
  try {
    handleDisableButton(true);
    const response = await apiRequestPost({
      url: '/calculation-module-admin/verify-code',
      data: {
        email: await ConfigsStore.get('resetPasswordEmail'),
        code: inputs?.code,
      },
    });
    if (response.status === 201 && response.data) {
      await AccountStore.set('account', response.data);
      notificationProvider.open?.({
        type: 'success',
        message: 'Success',
        description: 'Password reset successfully',
      });
      handleDisableButton(false);
      navigateToResetPassword();
    } else {
      notificationProvider.open?.({
        type: 'error',
        message: 'Error',
        description: 'Something went wrong',
      });
      handleDisableButton(false);
    }
  } catch (error: any) {
    handleDisableButton(false);
  }
};

export const ResetPasswordAction = async (
  password: string,
  navigateToLogIn?: () => void
) => {
  const inputs = { password };
  await AccountStore.get('account');
  try {
    const { data: resetPasswordData } = await apiRequestPost<boolean>({
      url: '/calculation-module-admin/reset-password',
      data: inputs,
      manualTrigger: true,
    });
    if (!resetPasswordData) {
      showToastify('error');
    }
    showToastify('success');
    await AccountStore.set('account', {});
    navigateToLogIn && navigateToLogIn();
    await ConfigsStore.set('resetPasswordEmail', '');
  } catch (error: any) {
    showToastify('error');
  }
};
