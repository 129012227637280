import React, { useState } from 'react';
import { IResourceComponentsProps, BaseRecord } from '@refinedev/core';
import {
  useTable,
  List,
  EditButton,
  DeleteButton,
  CreateButton,
  ShowButton,
} from '@refinedev/antd';
import { Table, Space, Skeleton } from 'antd';
import { Search } from '../../components/Search';
import { TierTypeEnum } from '../../types';
import { ExportCsvButton } from '../../components/buttons/CustomExportCsvButton';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export const TierList: React.FC<IResourceComponentsProps> = () => {
  // Destructure loading from tableProps
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const [isEmptySearch, setIsEmptySearch] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any>([]);

  const handleEmptySearch = () => {
    setIsEmptySearch(true);
  };

  if (searchResults?.length > 0) {
    tableProps.dataSource = searchResults;
  } else if (isEmptySearch) {
    tableProps.dataSource = [];
  }

  const paginationConfig =
    tableProps.dataSource !== undefined && searchResults.length > 0
      ? {
          total: searchResults.length > 0 ? searchResults.length : undefined,
        }
      : undefined;

  const mapEnumValue = (enumValue: string) => {
    switch (enumValue) {
      case 'visa':
        return TierTypeEnum.Visa;
      case 'master_card':
        return TierTypeEnum.MasterCard;
      case 'meeza':
        return TierTypeEnum.Meeza;
      default:
        return enumValue;
    }
  };

  return (
    <List
      headerButtons={[
        <ExportCsvButton key="export" path="tier" />,
        <CreateButton key="create" />,
      ]}
    >
      <Search
        path="tier"
        setSearchResults={setSearchResults}
        searchResults={searchResults}
        onEmptySearch={handleEmptySearch}
        onCancelSearch={() => setIsEmptySearch(false)}
      />
      {tableProps.loading ? ( // Show skeleton loading when table is loading
        <Skeleton active paragraph={{ rows: 17 }} />
      ) : (
        <Table
          {...(tableProps as any)}
          className="w-full"
          rowKey="id"
          pagination={paginationConfig}
        >
          <Table.Column dataIndex="name" title="Name" className="w-[30%]" />
          <Table.Column
            dataIndex={['type']}
            title="Scheme"
            render={(value: string) => mapEnumValue(value)}
            className="w-[30%]"
          />

          <Table.Column
            dataIndex={['is_deleted']}
            title="Active"
            className="w-[30%] text-nowrap"
            render={(value: any) => (
              <Space>
                {value ? (
                  <CloseCircleOutlined style={{ color: 'red' }} />
                ) : (
                  <CheckCircleOutlined style={{ color: 'green' }} />
                )}
                {value ? 'Inactive' : 'Active'}
              </Space>
            )}
          />

          <Table.Column
            dataIndex={['created_by', 'name']}
            title="Created By"
            className="w-[30%] text-nowrap"
          />

          <Table.Column
            title="Actions"
            dataIndex="actions"
            className="flex justify-start w-44"
            render={(_, record: BaseRecord) => (
              <div className="flex justify-end">
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                  <ShowButton hideText size="small" recordItemId={record.id} />
                  {record.is_deleted ? (
                    <DeleteButton hideText size="small" disabled />
                  ) : (
                    <DeleteButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  )}
                </Space>
              </div>
            )}
          />
        </Table>
      )}
    </List>
  );
};

export default TierList;
