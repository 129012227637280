import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Create, useForm, useSelect } from '@refinedev/antd';
import { Form, Select, Space } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export const ProductCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  const { selectProps: typeSelectProps } = useSelect({
    resource: 'type',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'is_deleted',
        operator: 'eq',
        value: false,
      },
    ],
  });

  const { selectProps: tierSelectProps } = useSelect({
    resource: 'tier',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'is_deleted',
        operator: 'eq',
        value: false,
      },
    ],
  });

  const { selectProps: segmentSelectProps } = useSelect({
    resource: 'segment',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'is_deleted',
        operator: 'eq',
        value: false,
      },
    ],
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Product Type"
          name={['type', 'id']}
          rules={[
            {
              required: false,
              message: 'Please enter the product type',
            },
          ]}
        >
          <Select {...(typeSelectProps as any)} />
        </Form.Item>

        <Form.Item
          label="Product Tier"
          name={['tier', 'id']}
          rules={[
            {
              required: false,
              message: 'Please enter the product tier',
            },
          ]}
        >
          <Select {...(tierSelectProps as any)} />
        </Form.Item>

        <Form.Item
          label="Product Segment"
          name={['segment', 'id']}
          rules={[
            {
              required: false,
              message: 'Please enter the product segment',
            },
          ]}
        >
          <Select {...(segmentSelectProps as any)} />
        </Form.Item>

        <Form.Item
          label="Status"
          name={['is_deleted']}
          initialValue={false}
          rules={[
            {
              required: false,
              message: 'Please select status',
            },
          ]}
        >
          <Select style={{ width: '200px' }}>
            <Select.Option value={false}>
              <Space>
                <CheckCircleOutlined style={{ color: 'green' }} />
                Active
              </Space>
            </Select.Option>
            <Select.Option value={true}>
              <Space>
                <CloseCircleOutlined style={{ color: 'red' }} />
                Inactive
              </Space>
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Create>
  );
};
