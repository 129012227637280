import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Edit, useForm, useSelect } from '@refinedev/antd';
import { Form, Select, Space } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export const ProductEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const productData = queryResult?.data?.data;

  const { selectProps: typeSelectProps } = useSelect({
    resource: 'type',
    optionLabel: 'name',
    optionValue: 'id',
  });

  const { selectProps: tierSelectProps } = useSelect({
    resource: 'tier',
    optionLabel: 'name',
    optionValue: 'id',
  });

  const { selectProps: segmentSelectProps } = useSelect({
    resource: 'segment',
    optionLabel: 'name',
    optionValue: 'id',
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          type: productData?.type?.id || undefined,
          tier: productData?.tier?.id || undefined,
          segment: productData?.segment?.id || undefined,
        }}
      >
        <Form.Item
          label="Type"
          name={['type', 'id']}
          rules={[
            {
              required: false,
            },
          ]}
          initialValue={productData?.type[0]?.type?.id}
        >
          <Select {...(typeSelectProps as any)} allowClear />
        </Form.Item>
        <Form.Item
          label="Tier"
          name={['tier', 'id']}
          rules={[
            {
              required: false,
            },
          ]}
          initialValue={productData?.tier[0]?.tier?.id}
        >
          <Select {...(tierSelectProps as any)} allowClear />
        </Form.Item>
        <Form.Item
          label="Segment"
          name={['segment', 'id']}
          rules={[
            {
              required: false,
            },
          ]}
          initialValue={productData?.segment[0]?.segment?.id}
        >
          <Select {...(segmentSelectProps as any)} allowClear={true} />
        </Form.Item>

        <Form.Item
          label="Status"
          name={['is_deleted']}
          initialValue={productData?.is_deleted}
          rules={[
            {
              required: false,
              message: 'Please select status',
            },
          ]}
        >
          <Select style={{ width: '200px' }}>
            <Select.Option value={false}>
              <Space>
                <CheckCircleOutlined style={{ color: 'green' }} />
                Active
              </Space>
            </Select.Option>
            <Select.Option value={true}>
              <Space>
                <CloseCircleOutlined style={{ color: 'red' }} />
                Inactive
              </Space>
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Edit>
  );
};
