import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { List, useTable } from '@refinedev/antd';
import { Dayjs } from 'dayjs';
import { Button, DatePicker, Form, Table } from 'antd';
import { RedoOutlined } from '@ant-design/icons';

export const ComplaintsList: React.FC<IResourceComponentsProps> = () => {
  const {
    searchFormProps,
    tableProps,
    tableQueryResult: { refetch },
    setFilters,
  } = useTable({
    syncWithLocation: true,
  });

  const handleDateChange = (date: Dayjs) => {
    if (date && date.isValid()) {
      setFilters(
        [
          {
            field: 'created_at',
            operator: 'eq',
            value: date.format('YYYY-MM-DD'),
          },
        ],
        'replace'
      );
    } else {
      setFilters([], 'replace');
    }
  };

  const handleReset = () => {
    searchFormProps.form?.resetFields();
    setFilters([], 'replace');
    refetch();
  };
  return (
    <List title={<div className="text-primaryRed lg:mb-9">Complaints</div>}>
      <Form layout="inline" {...searchFormProps} className="mb-4">
        <Form.Item name="fromDate" label="Created at">
          <DatePicker
            format="MM/DD/YYYY"
            placeholder="Created at"
            name="created_at"
            onChange={handleDateChange}
          />
        </Form.Item>
        <Button onClick={handleReset}>
          <RedoOutlined />
        </Button>
      </Form>
      <Table
        {...tableProps}
        rowKey="id"
        bordered={false}
        pagination={{
          position: ['bottomCenter'],
          nextIcon: false,
          prevIcon: false,
        }}
        className="w-full border border-gray-300 rounded-md"
        style={{ padding: 0, paddingBlockStart: '0 !important' }}
      >
        <Table.Column
          className="table-header"
          dataIndex="name"
          // title={<ColHeaderLeft title="Name" />}
          title="Name"
          render={(value) => (
            <span className="text-gray500 font-semibold">{value}</span>
          )}
          width={200}
        />
        <Table.Column
          className="table-header"
          dataIndex="message"
          // title={<ColHeaderLeft title="Message" />}
          title="Message"
          render={(value) => (
            <span className="max-w-sm line-clamp-none font-normal text-neutral90">
              {value}
            </span>
          )}
        />
        <Table.Column
          className="table-header"
          dataIndex="type"
          // title={<ColHeaderLeft title="Type" />}
          title="Type"
          render={(value) => {
            return (
              <span className="w-[100%] font-normal text-neutral90">
                {value}
              </span>
            );
          }}
        />
        <Table.Column
          className="table-header"
          dataIndex="phone_number"
          // title={<ColHeaderLeft title="Mobile number" />}
          title="Mobile Number"
          render={(value) => (
            <span className="w-[100%] font-normal text-neutral90">{value}</span>
          )}
          width={200}
        />
      </Table>
    </List>
  );
};
