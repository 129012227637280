import { AuthBindings } from '@refinedev/core';
import { LoginAction, LogoutAction } from '../Actions/AuthActions';
import { AccountStore, ConfigsStore } from '../Store';
import { notificationProvider } from './notificationProvider';

export const TOKEN_KEY = 'refine-auth';

export const authProvider: AuthBindings = {
  login: async ({ email, password }: { email: string; password: string }) => {
    return LoginAction({
      email,
      password,
    });
  },
  logout: async () => {
    if (await ConfigsStore.get('logged_in')) {
      await ConfigsStore.set('logged_in', false);
      await ConfigsStore.set('rememberMe', false);
      await ConfigsStore.set('cookieExpiration', '');
      await ConfigsStore.set('adminRoles', '');
      await ConfigsStore.set('resetPasswordEmail', '');
    }
    return {
      success: true,
      redirectTo: '/login',
    };
  },
  check: async () => {
    const isLoggedIn = await ConfigsStore.get('logged_in');
    if (isLoggedIn) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: '/login',
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const currentAccount = await AccountStore.get('account');
    return Promise.resolve({
      ...((currentAccount as object) || {}),
    });
  },
  onError: async (error) => {
    if (error.response.status === 401) {
      LogoutAction(true);
      notificationProvider.open?.({
        type: 'error',
        message: 'Error',
        description: 'Please login again',
      });
    }
    return { error };
  },
};
