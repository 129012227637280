export const API_URL = (() => {
  return window.location.host.includes('localhost') ||
    window.location.host.includes('local')
    ? 'https://loynova-loyalty.local.wtmsrv.com/api'
    : window.location.host.includes('stagin')
      ? 'https://loynova-loyalty.staging.wtmsrv.com/api'
      : 'https://api.loyalty.loynova.com/api';
})();
// loynova-loyalty.dev.wtmsrv.com
export const UPLOAD_URI = `${API_URL}/upload-cruds/files`;
export const GET_UPLOAD = `${API_URL}/upload-cruds`;
export const API_KEY = 'TE9ZTk9WQUxPWUFMVFlBUElLRVlHVUFSRDIwMjI';
// export const CONFIGS_ID = 1;
export const BUNDLE_ID = 'calculation.module.admin.loynova.loyalty';
