import React, { useState } from 'react';
import { IResourceComponentsProps, BaseRecord } from '@refinedev/core';
import {
  useTable,
  List,
  DeleteButton,
  DateField,
  TagField,
  CreateButton,
  FilterDropdown,
  EditButton,
} from '@refinedev/antd';
import { Table, Space, Select, Skeleton } from 'antd';
import { Search } from '../../components/Search';
import { ExportCsvButton } from '../../components/buttons/CustomExportCsvButton';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export const EarningMetricList: React.FC<IResourceComponentsProps> = () => {
  // Using useTable for pagination and sorting
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const [isEmptySearch, setIsEmptySearch] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any>([]);
  const isLoading = tableProps.loading; // Adjust based on your actual loading state

  const handleEmptySearch = () => {
    setIsEmptySearch(true);
  };

  if (searchResults?.length > 0) {
    tableProps.dataSource = searchResults;
  } else if (isEmptySearch) {
    tableProps.dataSource = [];
  }

  const paginationConfig =
    tableProps.dataSource !== undefined && searchResults.length > 0
      ? {
          total: searchResults.length > 0 ? searchResults.length : undefined,
        }
      : undefined;

  return (
    <List
      headerButtons={[
        <ExportCsvButton key="export" path="earning-metric" />,
        <CreateButton key="create" />,
      ]}
    >
      <Search
        path="earning-metric"
        setSearchResults={setSearchResults}
        searchResults={searchResults}
        onEmptySearch={handleEmptySearch}
        onCancelSearch={() => {
          setIsEmptySearch(false);
        }}
      />
      {isLoading ? ( // Show skeleton loading when isLoading is true
        <Skeleton active paragraph={{ rows: 17 }} />
      ) : (
        <Table {...tableProps} rowKey="id" pagination={paginationConfig}>
          <Table.Column
            dataIndex="name"
            title="Name"
            render={(value: any) => <TagField value={value} />}
          />

          <Table.Column
            title="Type"
            dataIndex={['earning_metric_value', 0, 'type']}
            key={'earning_metric_value_type'}
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Select
                  mode="multiple"
                  placeholder="Select Type"
                  options={[
                    { label: 'points', value: 'points' },
                    { label: 'cashback', value: 'cashback' },
                    {
                      label: 'points_and_cashback',
                      value: 'points_and_cashback',
                    },
                  ]}
                  style={{ minWidth: '200px', maxWidth: '400px' }}
                />
              </FilterDropdown>
            )}
            render={(value: any) => <TagField value={value} />}
          />
          <Table.Column
            title="Value"
            dataIndex={['earning_metric_value', 0, 'value']}
            render={(value: any) => <TagField value={value} />}
          />

          <Table.Column
            title="Product"
            dataIndex={[
              'earning_metric_value',
              0,
              'earning_metric_value_product',
            ]}
            key={'earning_metric_value_product'}
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Select
                  mode="multiple"
                  placeholder="Select Product"
                  options={Array.from(
                    new Set(
                      tableProps?.dataSource?.flatMap((item: any) =>
                        item?.earning_metric_value?.flatMap((value: any) =>
                          value?.earning_metric_value_product?.map(
                            (product: any) => {
                              return `${product?.product?.type[0]?.type?.name || ''}, ${product?.product?.tier[0]?.tier?.name || ''}, ${product?.product?.segment[0]?.segment?.name || ''}`;
                            }
                          )
                        )
                      )
                    )
                  ).map((name: string) => ({
                    label: name,
                    value: name,
                  }))}
                  style={{ minWidth: '200px', maxWidth: '400px' }}
                />
              </FilterDropdown>
            )}
            render={(earning_metric_value_product: any[]) => (
              <Space direction="vertical">
                {earning_metric_value_product?.map((product, index) => (
                  <TagField
                    key={index}
                    value={` ${product?.product.type
                      ?.map((t: { type: { name: string } }) => t.type.name)
                      .join(', ')}, 
                      ${product?.product.tier
                        ?.map((t: { tier: { name: string } }) => t.tier.name)
                        .join(', ')}, 
                     ${product?.product.segment
                       ?.map(
                         (s: { segment: { name: string } }) => s.segment.name
                       )
                       .join(', ')}`}
                  />
                ))}
              </Space>
            )}
          />
          <Table.Column
            dataIndex="created_at"
            title="Creation Date"
            render={(value: any) => <DateField value={value} />}
          />

          <Table.Column
            dataIndex={['created_by', 'name']}
            title="Created By"
            render={(value: any) => <TagField value={value} />}
          />

          <Table.Column
            dataIndex="is_deleted"
            title="Status"
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Select
                  mode="tags"
                  placeholder="Select Status"
                  options={[
                    { label: 'Active', value: 'false' },
                    { label: 'Inactive', value: 'true' },
                  ]}
                  style={{ minWidth: '200px', maxWidth: '400px' }}
                />
              </FilterDropdown>
            )}
            render={(value: any) => (
              <Space>
                {value ? (
                  <CloseCircleOutlined style={{ color: 'red' }} />
                ) : (
                  <CheckCircleOutlined style={{ color: 'green' }} />
                )}
                {value ? 'Inactive' : 'Active'}
              </Space>
            )}
          />

          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                {record.is_deleted ? (
                  <DeleteButton hideText size="small" disabled />
                ) : (
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                )}
              </Space>
            )}
          />
        </Table>
      )}
    </List>
  );
};
