import {
  apiRequest,
  apiRequestGet,
  apiRequestGetFile,
  apiRequestPost,
} from '../providers/rest-data-provider/utils/APIs';
import { AccountStore, ConfigsStore } from '../Store';
import { Language, OsEnum } from '../types';
import { notificationProvider } from '../providers/notificationProvider';
import { API_KEY } from '../configs';
import showToastify from '../providers/notificationProviderCustom';
import { LogoutAction } from './AuthActions';

export const CreateDeviceAction = async () => {
  const currentDevice = await ConfigsStore.get('device_id');
  if (currentDevice) return;
  try {
    const { data: deviceData } = await apiRequest<{ id: string }>({
      url: '/admin/create-device',
      method: 'POST',
      data: {
        os: OsEnum.browser,
        language: Language.ar,
      },
    });
    if (!deviceData) showToastify('Something went wrong!');

    await ConfigsStore.set('device_id', deviceData?.id);
  } catch (error: any) {
    showToastify(error.message);
  }
};
export const RewardUser = async (formData: {
  user_id: number;
  is_cashback: boolean;
  amount: number;
}) => {
  try {
    const response = await apiRequest<{
      Result: boolean & { _text: string };
    }>({
      url: '/account-cruds/reward-user',
      method: 'POST',
      data: formData,
    });

    return response;
  } catch (error: any) {
    showToastify(error.message);
  }
};
export const EditRecordAction = async (
  type: string,
  id: any,
  data: any,
  refetch?: () => void,
  withRefresh?: boolean,
  resetForm?: () => void,
  withoutAlert?: boolean
) => {
  try {
    await apiRequest<boolean>({
      url: `/${type}/${id}`,
      method: 'PATCH',
      data,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (await AccountStore.get('account'))?.tokens.access_token
        }`,
      },
    });

    resetForm && resetForm();
    refetch && refetch();
    !withoutAlert &&
      notificationProvider.open?.({
        type: 'success',
        message: 'Success',
        description: 'Record updated successfully',
      });

    withRefresh && window.location.reload();
  } catch (error: any) {
    notificationProvider.open?.({
      type: 'error',
      message: 'Error',
      description: error?.message,
    });
  }
};
export const CreateRecordAction = async (
  url: string,
  data: any,
  navigateToEdit?: (url: string, id?: string) => void,
  goBack?: () => void,
  refetch?: () => void,
  resetForm?: () => void
) => {
  try {
    const { data: res } = await apiRequestPost<{ id: any }>({
      url,
      data,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (await AccountStore.get('account'))?.tokens.access_token
        }`,
      },
    });
    if (res) {
      resetForm && resetForm();
      goBack && goBack();
      navigateToEdit && navigateToEdit(url, res?.id);
      refetch && refetch();
      notificationProvider.open?.({
        type: 'success',
        message: 'Success',
        description: 'Record created successfully',
      });
    }
  } catch (error: any) {
    notificationProvider.open?.({
      type: 'error',
      message: 'Error',
      description: 'Error in creating record',
    });
  }
};

export const GetOneRecordAction = async (type: string, id: string) => {
  try {
    await apiRequestGet<boolean>({
      url: `/${type}/${id}`,
      method: 'GET',
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (await AccountStore.get('account'))?.tokens.access_token
        }`,
      },
    });
  } catch (error: any) {
    notificationProvider.open?.({
      type: 'error',
      message: 'Error',
      description: error?.message,
    });
  }
};
// /api/rewarding-transactions/customer-details

export const GetCustomerDetails = async () => {
  try {
    await apiRequestGet<boolean>({
      url: `/rewarding-transactions/customer-details`,
      method: 'GET',
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (await AccountStore.get('account'))?.tokens.access_token
        }`,
      },
    });
  } catch (error: any) {
    notificationProvider.open?.({
      type: 'error',
      message: 'Error',
      description: error?.message,
    });
  }
};
export const ApproveDocumentAction = async (
  document_id: number,
  account_id: number,
  refetch?: () => void
) => {
  try {
    await apiRequest<boolean>({
      url: `/provider-documents-cruds/approve-document/${account_id}`,
      method: 'PATCH',
      data: { document_id },
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (await AccountStore.get('account'))?.tokens.access_token
        }`,
      },
    });
    refetch && refetch();
    notificationProvider.open?.({
      type: 'success',
      message: 'Success',
      description: 'Document approved successfully',
    });
  } catch (error: any) {
    notificationProvider.open?.({
      type: 'error',
      message: 'Error',
      description: error?.message,
    });
  }
};

export const RejectDocumentAction = async (
  document_id: number,
  refetch: () => void
) => {
  try {
    await apiRequest<boolean>({
      url: `/provider-documents-cruds/reject-document/${document_id}`,
      method: 'PATCH',
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (await AccountStore.get('account'))?.tokens.access_token
        }`,
      },
    });
    refetch && refetch();
    notificationProvider.open?.({
      type: 'success',
      message: 'Success',
      description: 'Document rejected successfully',
    });
  } catch (error: any) {
    notificationProvider.open?.({
      type: 'error',
      message: 'Error',
      description: error?.message,
    });
  }
};

export const SearchAction = async (
  url: string,
  setSearchResults: (val: any) => void
) => {
  try {
    const { data } = await apiRequestGet<boolean>({
      url,
      method: 'GET',
      headers: {
        api_key: API_KEY,
        // CONFIGS_ID,
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (await AccountStore.get('account'))?.tokens.access_token
        }`,
      },
    });
    setSearchResults(data || []);
  } catch (error: any) {
    notificationProvider.open?.({
      type: 'error',
      message: 'Error',
      description: error?.message || 'Unknown error occurred',
    });
  }
};

export const exportCsvAction = async (url: string) => {
  let isSuccess = false;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const entries = urlParams.entries();

  const filters = {} as any;
  for (const entry of entries) {
    const keyParts = entry[0].match(/filters\[(\d+)\]\[(\w+)\]/);
    if (keyParts) {
      const index = Number(keyParts[1]);
      const property = keyParts[2];
      const value = entry[1];
      if (property === 'field') {
        filters[value] = filters[value] || [];
      } else if (property === 'value') {
        const field = Object.keys(filters)[index];
        if (filters[field] === undefined) {
          filters[field] = value; // Set as string if it's the first value
        } else {
          if (!Array.isArray(filters[field])) {
            filters[field] = [filters[field]]; // Convert to array if it's the second value
          }
          (filters[field] as any[]).push(value);
          if ((filters[field] as any[]).length === 1) {
            filters[field] = (filters[field] as any[])[0]; // If there's only one value, set it as a string
          }
        }
      }
    }
  }

  try {
    const res = await apiRequestGetFile<any>({
      url,
      method: 'GET',
      params: {
        ...filters,
      },
      headers: {
        api_key: API_KEY,
        // CONFIGS_ID,
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (await AccountStore.get('account'))?.tokens.access_token
        }`,
      },
    });

    const blob = new Blob([res.data]);
    const urlObject = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = urlObject;
    link?.setAttribute('download', `${url.replace('/excel-file', '')}.xlsx`);

    link.click();
    URL.revokeObjectURL(urlObject);

    isSuccess = true;
  } catch (error: any) {
    notificationProvider.open?.({
      type: 'error',
      message: 'Download Failed',
      description: 'There was an error while downloading the CSV file.',
    });
    showToastify(error.message);
  } finally {
    if (isSuccess) {
      notificationProvider.open?.({
        type: 'success',
        message: 'Success',
        description: 'File downloaded successfully',
      });
    }
  }
};

export const approveGoldRequestAction = async (
  gold_request_id: number,
  final_price: number,
  refetch: () => void,
  showModal: () => void
) => {
  try {
    await apiRequest<boolean>({
      url: `/gold-requests/${gold_request_id}/status`,
      method: 'POST',
      data: {
        final_price: final_price.toString(),
        status: 'approved',
      },
    });

    refetch && refetch();
    showModal();
  } catch (error: any) {
    return error;
  }
};
