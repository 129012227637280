import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps, BaseRecord } from '@refinedev/core';
import {
  useTable,
  List,
  EditButton,
  DeleteButton,
  CreateButton,
  ShowButton,
} from '@refinedev/antd';
import { Table, Space, Skeleton } from 'antd'; // Import Skeleton here
import { Search } from '../../components/Search';
import { ExportCsvButton } from '../../components/buttons/CustomExportCsvButton';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export const TypeList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({ syncWithLocation: true });
  const [isLoading, setIsLoading] = useState<boolean>(true); // Manage loading state
  const [isEmptySearch, setIsEmptySearch] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any>([]);

  const handleEmptySearch = () => {
    setIsEmptySearch(true);
  };

  useEffect(() => {
    // Simulate fetching data
    const fetchData = async () => {
      setIsLoading(true); // Start loading
      // Simulate a delay to fetch data
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate loading time
      setIsLoading(false); // End loading
    };

    fetchData();
  }, []);

  if (searchResults?.length > 0) {
    tableProps.dataSource = searchResults;
  } else if (isEmptySearch) {
    tableProps.dataSource = [];
  }

  const paginationConfig =
    tableProps.dataSource !== undefined && searchResults.length > 0
      ? {
          total: searchResults.length > 0 ? searchResults.length : undefined,
        }
      : undefined;

  return (
    <List
      headerButtons={[
        <ExportCsvButton key="export" path="type" />,
        <CreateButton key="create" />,
      ]}
    >
      <Search
        path="type"
        setSearchResults={setSearchResults}
        searchResults={searchResults}
        onEmptySearch={handleEmptySearch}
        onCancelSearch={() => setIsEmptySearch(false)}
      />

      {isLoading ? ( // Show skeleton loading when isLoading is true
        <Skeleton
          active
          paragraph={{ rows: 17 }} // Adjust the height here
        />
      ) : (
        <Table {...tableProps} rowKey="id" pagination={paginationConfig}>
          <Table.Column dataIndex="name" title="Name" />
          <Table.Column dataIndex={['created_by', 'name']} title="Created By" />
          <Table.Column
            dataIndex={['is_deleted']}
            title="Active"
            className="w-[30%] text-nowrap"
            render={(value: any) => (
              <Space>
                {value ? (
                  <CloseCircleOutlined style={{ color: 'red' }} />
                ) : (
                  <CheckCircleOutlined style={{ color: 'green' }} />
                )}
                {value ? 'Inactive' : 'Active'}
              </Space>
            )}
          />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            className="w-44"
            render={(_, record: BaseRecord) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
                {record.is_deleted ? (
                  <DeleteButton hideText size="small" disabled />
                ) : (
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                )}
              </Space>
            )}
          />
        </Table>
      )}
    </List>
  );
};
