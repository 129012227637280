import React from 'react';
import { IResourceComponentsProps, BaseRecord } from '@refinedev/core';
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  DateField,
  BooleanField,
} from '@refinedev/antd';
import { Table, Space, Skeleton } from 'antd';

export const DashBoardAdminList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List>
      {tableProps.loading ? ( // Check if the table is loading
        <Skeleton active paragraph={{ rows: 17 }} /> // Show skeleton loading
      ) : (
        <Table {...(tableProps as any)} rowKey="id">
          <Table.Column dataIndex={['account_id']} title="Id" />
          <Table.Column dataIndex="role" title="Role" />
          <Table.Column dataIndex={['account', 'name']} title="Name" />
          <Table.Column dataIndex={['account', 'email']} title="Email" />
          <Table.Column
            dataIndex={['created_at']}
            title="Created At"
            render={(value: any) => <DateField value={value} />}
          />
          <Table.Column
            dataIndex={['is_deleted']}
            title="Is Deleted"
            render={(value: any) => <BooleanField value={value} />}
          />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  recordItemId={record.account_id}
                />
                <ShowButton
                  hideText
                  size="small"
                  recordItemId={record.account_id}
                />
                <DeleteButton
                  hideText
                  size="small"
                  recordItemId={record.account_id}
                />
              </Space>
            )}
          />
        </Table>
      )}
    </List>
  );
};

export default DashBoardAdminList;
