import React from 'react';
import { Modal, message } from 'antd';
import { useNavigation } from '@refinedev/core';
import { apiRequest } from '../../providers/rest-data-provider/utils/APIs';

interface BulkPointsManagementModalProps {
  visible: boolean;
  onClose: (value: boolean) => void;
  id?: string;
  handleModalOpen: (value: boolean) => void;
}

const SecurityQuestionModal: React.FC<BulkPointsManagementModalProps> = ({
  visible,
  id,
  handleModalOpen,
}) => {
  const { list } = useNavigation();
  const [value, setValue] = React.useState('');
  const handleUpdateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onClose = async () => {
    list('account-cruds/financial-data');
    handleModalOpen(false);
  };

  const handleConfirm = async () => {
    try {
      const response = await apiRequest<boolean>({
        url: 'rewarding-transactions/check-last-transaction-amount',
        method: 'POST',
        data: {
          account_id: id ? parseInt(id, 10) : null,
          amount: parseInt(value, 10),
        },
      });
      if (response.data == true) {
        handleModalOpen(false);
      } else {
        handleModalOpen(false);
        list('account-cruds/financial-data');
        message.error('Wrong answer');
      }
    } catch (error) {
      list('account-cruds/financial-data');
      message.error('Wrong answer');
    }
  };

  return (
    <>
      <Modal
        open={visible}
        onCancel={() => onClose()}
        centered
        width={498}
        title={<h1 className=" text-lg">Security question</h1>}
        footer={null}
        styles={{ content: { borderRadius: '24px' } }}
      >
        <div className="flex flex-col">
          <span className="text-sm text-gray-500 pb-5">
            Please enter the answer to unlock customer profile.
          </span>
          <span className=" w-full border-2"></span>

          <span className="text-sm py-3 text-gray-500">
            Amount of your last transaction
          </span>
          <h2 className="text-sm pb-2">Enter your answer</h2>
          <div className="pb-8">
            <input
              className="w-full  border-2 rounded-lg p-2"
              type="text"
              placeholder="EGP"
              onChange={(event) => handleUpdateValue(event)}
              style={{ color: '#040C82' }}
            />
          </div>
          <div className="flex gap-3">
            <button
              onClick={() => onClose()}
              className="w-full font-semibold border-2 rounded-lg p-2"
            >
              Back
            </button>
            <button
              onClick={() => handleConfirm()}
              className="w-full font-semibold border-2 rounded-lg p-2 text-white bg-[#040C82]"
            >
              Next
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default SecurityQuestionModal;
